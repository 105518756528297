<template>
  <div>
    <form-summary
      v-if="!isValid"
      class="form-errors alert alert-danger"
      :validator="$v.form"
      :attributes="attributes"
    />

    <LinkedTypes :restaurantId="itemId" :linkedTypes.sync="form.restaurant_types" />
  </div>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

const first_type_must_have_image = (types) => {
  let typesCopy = [...types];
  const sorted = typesCopy.sort((a,b) => a.index - b.index);
  return !!(sorted[0]?.type_image_path)
}

export default {
  name: 'RestaurantWizardStep2',
  components: {
    LinkedTypes: () => import('@/views/restaurants/linked-types/LinkedTypes'),
  },
  data() {
    return {
      form: {
        restaurant_types: []
      },

      // Vuelidate-error-extractor
      attributes: {
        restaurant_types: 'Restaurant type',
      },
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      restaurant_types: { required, first_type_must_have_image },
    }
  },

  async mounted() {
    this.form = this.getForm();

    this.$nextTick(function () {
      this.$emit("loaded");
    });
  },

  computed: {
    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    itemId() { return this.$route.params.id },
  },

  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid);
      // return !(field.$invalid || field.$model === "");
    },

    validate() {
      this.$v.$touch()
    },

    getForm() {
      const storedForm = localStorage.getItem('pending-restaurant');

      if (storedForm) {
        var form = JSON.parse(storedForm);
        form.restaurant_types ||= [];
        form.restaurant_types?.sort((a,b) => a.index - b.index);
        return form;
      } else {
        this.$emit("reset");
      }
    },

    async next() {
      if (!this.isValid) {
        window.scroll({top: 180, behavior: 'smooth'});
        this.validate();
        return;
      }

      localStorage.setItem('pending-restaurant', JSON.stringify(this.form));
      this.$emit("next", true);
    },

    async previous() {
      localStorage.setItem('pending-restaurant', JSON.stringify(this.form));
      this.$emit("previous", true);
    },
  }
}
</script>
